import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SEOService } from '@/helpers/services/seo.service';
import { HelpVideo } from '@/helpers/models/helpVideo.model';
import { ProductType } from '@/helpers/enums/productType.enum';
import { Product } from '@/helpers/models/product.model';
import { ProductService } from '@/helpers/services/products.service';
import { SearchResult } from '@/helpers/models/searchResult.model';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit, AfterViewInit {
  private fragment: string;

  loading: boolean = false;
  products: Product [];
  productSections: string[] = [];
  filteredProductChildren: Product[] = []

 // multiChartsVideos: HelpVideo[] = [
    /*{
      title: 'Utilizzo base della piattaforma MultiCharts',
      url: 'https://player.vimeo.com/video/637156287',
      id: '637156287'
    },
    {
      title: 'Indicatore Simple Fair Value per MultiChart',
      url: 'https://player.vimeo.com/video/636259792',
      id: '636259792'
    },
    {
      title: 'Indicatore Velocità SFV  per MultiCharts',
      url: 'https://player.vimeo.com/video/636274513',
      id: '636274513'
    },*/
/*     {
      title: 'Come installare MultiCharts in modalità “riparazione”',
      url: 'https://vimeo.com/745352337',
      id: '745352337'
    }
  ] */

  /* metatraderVideos: HelpVideo[] = [
    {title: 'Come installare gli indicatori per MT4 e MT5', url: 'https://player.vimeo.com/video/619063122'},
    {title: 'Indicatore di Ciclo per MT4 e MT5', url: 'https://player.vimeo.com/video/619063422'},
    {title: 'Indicatore DNA per MT4 e MT5', url: 'https://player.vimeo.com/video/619064238'},
    {title: 'Indicatore Velocità di Simple Fair Value e velocità', url: 'https://player.vimeo.com/video/619063313'},
    {title: 'Indicatore Bande su MT4 e MT5', url: 'https://player.vimeo.com/video/619064066'},
  ] */

/*   discordVideos: HelpVideo[] = [
    {title: 'Tutorial Discord 1', url: 'https://player.vimeo.com/video/670296897'}
  ] */

  constructor(
    private route: ActivatedRoute,
    private seo: SEOService,
    private productService: ProductService) {
    this.seo.updateTitle('Aiuto | Top-Trading Academy')
    this.seo.updateDescription('MultiCharts, MetaTrader, AMP Futures, Discord, webinar ed indicatori. Trova una risposta alle domande più comuni.')
  }

  async ngOnInit() {
    this.route.fragment.subscribe(fragment => {
      this.fragment = fragment;
    })
    await this.searchProducts();
  }

  ngAfterViewInit() {
    scrollTo(0, 0)
    setTimeout(() => {
      const element = document.getElementById(this.fragment);
      const y = element.getBoundingClientRect().top + window.pageYOffset + (-70);
      window.scrollTo({top: y, behavior: 'smooth'});
      element
        .parentElement
        .parentElement
        .classList.add('highlight')
    }, 1500)
  };

/*   async searchProducts() {
    this.loading = true;
    try {
      const _products = await this.productService.searchProducts('', 0, 0, 0);
      this.products = _products.result.filter(x => x.productType === ProductType.Aiuto);
      await this.getCategories();
    } catch (error) {
      console.error('Errore nella chiamata API:', error);
    } finally {
      this.loading = false;
    }
  }  */

  async searchProducts() {
    this.loading = true
    await this.productService.searchProducts('', 0, 0, 0)
      .then(async (_products: SearchResult<Product>) => {
        this.products = _products.result
          .filter(x => x.productType === ProductType.Aiuto)
        await this.getCategories()
      }).finally(() => this.loading = false)
  }

  async getCategories() {
    const distinct = (value, index, self) => {
      return self.indexOf(value) === index
    }

    this.productSections = this.products
      .map(x => x.productSection)
      .filter(distinct)
  }
}
