import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './helpers/pages/menu/menu.component';
import { FooterComponent } from './helpers/pages/footer/footer.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from './helpers/utils/jwt.interceptor';
import { ErrorInterceptor } from './helpers/utils/error.interceptor';
import { HomeComponent } from './pages/home/home.component';
import { LoginModalComponent } from './helpers/modals/login-modal/login-modal.component';
import { RegistrationModalComponent } from './helpers/modals/registration-modal/registration-modal.component';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from './helpers/modules/shared.module';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from '@envs/environment';
import { MyProductDetailComponent } from './pages/products/my-product-detail/my-product-detail.component';
import { MyProductsListComponent } from './pages/products/my-products-list/my-products-list.component';
import { CheckoutComponent } from '@components/checkout/checkout.component';
import { CheckoutSuccessfulComponent } from '@components/checkout-successful/checkout-successful.component';
import { CheckoutCanceledComponent } from '@components/checkout-canceled/checkout-canceled.component';
import { ShoppingCartComponent } from '@components/shopping-cart/shopping-cart.component';
import { HelpComponent } from '@components/help/help.component';
import { TermsConditionsComponent } from '@components/terms-conditions/terms-conditions.component';
import { ContactsComponent } from '@components/contacts/contacts.component';
import { CookiesPopUpComponent } from '@components/cookies-pop-up/cookies-pop-up.component';
import { IndicatorsRequestComponent } from '@components/indicators-request/indicators-request.component';
import { TawkToService } from '@services/tawkto.service';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import { SEOService } from '@services/seo.service';
import { AccountComponent } from './pages/account/account.component';
import {
  IndicatorInstructionVideoComponent
} from './helpers/modals/indicator-instruction-video/indicator-instruction-video.component';
import {
  CheckoutSuccessfulNoregComponent
} from '@components/checkout-successful-noreg/checkout-successful-noreg.component';
import { ConfirmAccountComponent } from './pages/account/confirm-account/confirm-account.component';
import { LandscapeMobileComponent } from './helpers/pages/landscape-mobile/landscape-mobile.component';
import { KingOfTradingComponent } from './pages/king-of-trading/king-of-trading.component';
import { ClipboardModule } from 'ngx-clipboard';
import {
  CheckoutSubscriptionSuccessComponent
} from '@components/checkout-subscription-success/checkout-subscription-success.component';
import { MyProductCardComponent } from '@components/my-product-card/my-product-card.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { registerLocaleData } from "@angular/common";
import localeIt from '@angular/common/locales/it';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from "ngx-google-analytics";
import {
  DiscordRedirectModalComponent
} from '@components/helpers/discord-redirect-modal/discord-redirect-modal.component';
import { DisclaimerComponent } from '@components/disclaimer/disclaimer.component';
import { ProductsModule } from "@/pages/products/products.module";
import { CalendlyButtonComponent } from './components/calendly-button/calendly-button.component';
import { MyProductCoverComponent } from './components/my-product-cover/my-product-cover.component';
import { MyProductPlatformComponent } from './components/my-product-platform/my-product-platform.component';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { SafeUrlPipe } from './safe-url.pipe';

registerLocaleData(localeIt)

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    FooterComponent,
    HomeComponent,
    LoginModalComponent,
    RegistrationModalComponent,
    DashboardComponent,
    KingOfTradingComponent,
    AccountComponent,
    MyProductDetailComponent,
    MyProductsListComponent,
    ShoppingCartComponent,
    CheckoutComponent,
    CheckoutSuccessfulComponent,
    CheckoutCanceledComponent,
    HelpComponent,
    TermsConditionsComponent,
    ContactsComponent,
    CookiesPopUpComponent,
    IndicatorsRequestComponent,
    IndicatorInstructionVideoComponent,
    CheckoutSuccessfulNoregComponent,
    ConfirmAccountComponent,
    LandscapeMobileComponent,
    KingOfTradingComponent,
    CheckoutSubscriptionSuccessComponent,
    MyProductCardComponent,
    DiscordRedirectModalComponent,
    DisclaimerComponent,
    CalendlyButtonComponent,
    MyProductPlatformComponent,
    MyProductCoverComponent,
    VideoPlayerComponent,
    SafeUrlPipe,
  ],
  imports: [
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      progressBar: true
    }), // ToastrModule added
    NgxStripeModule.forRoot(environment.paymentApiKey), // Stripe npm
    NgxJsonLdModule,
    ClipboardModule,
    NgxPaginationModule,
    NgxGoogleAnalyticsModule.forRoot('G-JKJXQB6CNJ'),
    NgxGoogleAnalyticsRouterModule,
    ProductsModule,
  ],
  providers: [
    SEOService,
    HttpClientModule,
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    TawkToService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
